.container {
    display: flex;
    align-items: center;

    svg {
        width: 20px;
        height: 20px;
    }

    .langName {
        display: none;
    }

    .langDropDown { 
        position: absolute;
        top: 50px;
        width: 20px;
        background-color: #eee;
        padding: 5px 0;

        svg {
            width: 16px;
            height: 16px;
            margin: 5px 2px;
        }

        .langDDItem {
            .langDDItemName {
                display: none;
            }
        }
    }

    @media (min-width: 550px) {
        svg {
            width: 20px;
            height: 20px;
            margin: 0 10px 0 5px;
        }

        .langName {
            display: block;
            font-size: 9px;
        }

        .langDropDown {
            position: absolute;
            top: 38px;
            width: 115px;
            background-color: #eee;
            padding: 5px 0;
            
            .langDDItem {
                display: flex;
                align-items: center;
                height: 30px;
                
                svg {
                    width: 20px;
                    height: 20px;
                    margin: 0 10px 0 5px;
                }

                .langDDItemName {
                    display: block;
                    font-size: 10px;
                    color: black;
                }
            }
        }
    }
}