@import "../_variables.scss";

.cookieConsentBanner {
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  width:100%;
  color:white;
  background-color: rgb(30, 30, 30);
  padding: 0px 25px;
  z-index:50;

  p {
    font-size: 16px;
    text-align: center;
    margin-right: 10px;
    line-height: 25px;

    a {
      color: #e06939;
    }
  }

  .okButton {
    color: rgb(249, 249, 249);
    border: 2px solid rgb(224, 105, 57);
    background-color: rgb(224, 105, 57);
    width: 68px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: rgb(254, 135, 87);
    }
  }
}

.cookieConsentBanner.hide {
  display: none;
}