
.copy {
    margin-top: 20px;
    width: 100%;
    text-align: center;
    float: left;
    padding: 10px;
    background-color: #FFFFFF;
    color:  #090909;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
}

.inftabs {
    color: #020007;
}

.content .cWrapper .copy span {
    font-family: 'arial';
    font-size: 10pt;
    color: #020007;
}


