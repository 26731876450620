.container {
    position: relative;
    height: 58px;

    .fullWidth, .fullWidth.reduced,
    .menu, .menu.reduced {
        position: fixed;
        background-color: white;
        width: 100%;
        height: 58px;
        background: #FFF;
        border-bottom: 1px solid #d0d0d0;
    }

    .fullWidth,
    .fullWidth.reduced {
        left: 0;
    }

    .menu,
    .menu.reduced {
        display: flex;
        align-items: center;
        z-index: 999;
        max-width: 1164px;

        .logoLeft,
        .logoLeft.reduced {
            .logoSmall {
                display: block;
            }

            .logoLarge {
                display: none;                
            }

            svg {
                width: 220px;
                height: 33px;
                margin-top: 10px;
            }
        }

        .menuButtons,
        .menuButtons.reduced {
            position: absolute;
            top: 11px;
            right: 22px;
            display: flex;
            align-items: center;

            .searchContainer {
                display: none;
            }

            .langButtonMobile {
                display: flex;
                padding: 10px;                
            }

            .hamburgerButton {       
                display: flex;         
                background-color: #000;
                width: 35px;
                height: 35px;

                button {  
                    
                    position: relative;
                    width: 35px;
                    height: 35px;
                    cursor: pointer;
                    float: left;
                    background: #000;
                    border: 0;
                    outline: 0;
                    
                    &::before {
                        display: block;
                        position: absolute;
                        bottom: 10px;
                        left: 7px;
                        right: 7px;
                        height: 3px;
                        margin: -1.5px 0 0;
                        float: left;
                        background: #FFF;
                        content: '';
                    }

                    span {
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 7px;
                        right: 7px;
                        height: 3px;
                        margin: -1.5px 0 0;
                        float: left;
                        background: #FFF;
                    }
                
                    &::after {
                        display: block;
                        position: absolute;
                        top: 10px;
                        left: 7px;
                        right: 7px;
                        height: 3px;
                        margin: -1.5px 0 0;
                        float: left;
                        background: #FFF;
                        content: '';
                    }
                }
                
                .hamburgerDropDown {
                    position: absolute;
                    top: 50px;
                    right: 0px;
                    width: 180px;
                    background-color: #000;                                       

                    .hamburgerItem {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        height: 30px;
                        padding: 0 10px;
                        color: #FFF;
                        font-size: 13px;

                        &:hover {
                            color: #3e3e3e;
                            background-color: rgb(238, 238, 248);;
                        }
                    }
                }
            }
            
            .langButtonDesktop {
                display: none;
            }
        }

        .logoRight,
        .logoRight.reduced {
            display: none;
        }
    }

    @media (min-width: 480px) {
        .menu,
        .menu.reduced {               
            .logoLeft,
            .logoLeft.reduced {    
                svg {
                    width: 320px;
                    height: 49px;
                    margin-top: 0px;
                }
            }
        }
    }

    @media (min-width: 550px) {
        .menu,
        .menu.reduced {    
            .menuButtons,
            .menuButtons.reduced {
                position: absolute;
                top: 11px;
                right: 22px;
                display: flex;
                align-items: center;

                .searchContainer {
                    display: flex;   
                    width: 140px;
                    margin-right: 20px;     
                }

                .langButtonMobile {
                    display: none;
                }

                .hamburgerButton {
                    display: none;
                }

                .langButtonDesktop {
                    background: #fff none repeat scroll 0 0;
                    border: 1px solid #eee;
                    border-radius: 0;
                    white-space: nowrap;
                    width: 115px;
                    height: 35px;
                    padding: 0;
                    display: flex;
                    align-items: center;       
                }                
            }
        }
    }

    @media (min-width: 700px) {
        height: 110px;

        .fullWidth, .fullWidth.reduced,
        .menu, .menu.reduced {
            height: 110px;
            transition: all .3s linear;
    
            .logoLeft,
            .logoLeft.reduced {
                display: flex;
                align-items: center;
                width: 450px;
                height: 68px;
                transition: all .3s linear;
    
                svg {
                    width: 100%;
                    height: 100%;
                }
            }

            .logoLeft.reduced {
                width: 300px;
                height: 45px;
            }

            .menuButtons,
            .menuButtons.reduced {
                top: 40px;
                transition: all .3s linear;
            }

            .menuButtons.reduced {
                top: 20px;
            }
        }

        .menu.reduced, .fullWidth.reduced {
            height: 80px;
        }
    }

    @media (min-width: 990px) {
        .menu,
        .menu.reduced {
            
            .logoLeft,
            .logoLeft.reduced { 
                width: 430px;
                height: 63px;
                transition: all .3s linear;

                .logoSmall {
                    display: none;
                }

                .logoLarge {
                    display: block;
                }

                svg {
                    width: 100%;
                    height: 100%;
                }
            }
            
            .logoLeft.reduced {
                width: 300px;
                height: 44px;
            }

            .menuButtons,
            .menuButtons.reduced {
                right: 230px;
                transition: all .3s linear;
 
                .searchContainer {
                    width: 180px;
                }
            }
            
            .menuButtons.reduced {
                right: 99px;
                top: 21px;
            }

            .logoRight,
            .logoRight.reduced {
                display: block;
                position: absolute;
                right: 20px;
                top: 5px;
                width: 190px;
                height: 211px;
                transition: all .3s linear;
                
                svg {
                    width: 100%;
                    height: 100%;
                }
            }
            
            .logoRight.reduced {
                width: 54px;
                height: 60px; 
                right: 15px;
                top: 12px;
            }
        }
    }
}