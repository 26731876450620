.backToTop {
    position: fixed;
    bottom: 50px;
    right: 20px;
    border: none;
    background-color: transparent;
    z-index: 99;

    opacity: 0;
    transition: opacity 500ms linear;
    visibility: hidden;

    &.fadeIn {
        opacity: 1;
        transition: opacity 500ms linear;
        visibility: visible;
    }

    svg {
        width: 50px;
        height: 50px;
    }
}